import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line

import LogoImage from '../../images/AfrikanCodersLogo.svg';
import { ReactComponent as FacebookIcon } from '../../images/facebook-icon.svg';
import { ReactComponent as LinkedinIcon } from '../../images/Linkedin.svg';
import { ReactComponent as YoutubeIcon } from '../../images/youtube-icon.svg';

const Container = tw.div`relative bg-white -mx-8 -mb-8 px-8`;
const FiveColumns = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20 flex flex-wrap justify-between`;

const FooterLink = styled.a`
  ${tw`cursor-pointer  font-bold`}
`;

const ArrowImage = tw.img`w-20 h-[100px] hidden md:block absolute top-[-50px] right-[650px]`;

const DivLink = tw.div`flex flex-col mt-3`;

const Column = tw.div`md:w-1/5 mx-auto  md:mx-2`;
const WideColumn = tw(Column)`text-center md:text-left w-full md:w-2/5 mb-10 md:mb-0`;

const ColumnHeading = tw.h5`font-bold`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:text-primary-500 hocus:border-primary-500 pb-1 transition duration-300`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-20 sm:w-16`;
const LogoText = tw.h5`ml-2 text-xl font-black text-primary-500`;

const CompanyDescription = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto md:mx-0 md:mr-4 text-blue-951 font-thin`;

const SocialLinksContainer = tw.div`mt-4 `;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-1 lg:p-2 rounded-full bg-yellow-950 text-gray-100 hover:bg-gray-900 transition duration-300 mr-4`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

export default () => {
  return (
    <Container>
      <FiveColumns>
        <ArrowImage className="" src="/arrow-content.svg" />
        <WideColumn>
          <LogoContainer>
            <LogoImg src={LogoImage} />
            {/* <LogoText>Treact Inc.</LogoText> */}
          </LogoContainer>
          <CompanyDescription>
            &copy; 2022 AfrikanCoders. <p></p>All rights reserved.
          </CompanyDescription>
          <CompanyDescription>All trademarks, logos, and brand names are the property of Sintaxy</CompanyDescription>
          <DivLink>
            <FooterLink href="#">Terms of Service</FooterLink>
            <FooterLink href="#">Privacy Policy</FooterLink>
          </DivLink>
        </WideColumn>
        <Column>
          <ColumnHeading>Contact:</ColumnHeading>
          <LinkList>
            <LinkListItem>+238 5802943</LinkListItem>
            <LinkListItem>+238 9700405</LinkListItem>
            {/* <LinkListItem>
              <Link href="mailto:support@servana.com">support@servana.com</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="#">Sales</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="#">Report Abuse</Link>
            </LinkListItem> */}
          </LinkList>
        </Column>
        <Column>
          <SocialLinksContainer>
            <SocialLink target="_blank" href="https://www.facebook.com/profile.php?id=100087086357805">
              <FacebookIcon />
            </SocialLink>
            <SocialLink target="_blank" href="https://www.linkedin.com/company/afrikancoders/">
              <LinkedinIcon />
            </SocialLink>
            {/* <SocialLink href="https://youtube.com">
              <YoutubeIcon />
            </SocialLink> */}
          </SocialLinksContainer>
        </Column>
      </FiveColumns>
    </Container>
  );
};
