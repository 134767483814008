import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';

import Header, { NavLink, NavLinks, LogoLink, NavToggle, DesktopNavLinks } from '../headers/light.js';
import ResponsiveVideoEmbed from '../../helpers/ResponsiveVideoEmbed.js';

const Image = tw.img`bg-cover `;

const ArrowImage = tw.img`w-20 h-[100px] hidden lg:block absolute top-[315px] left-[20px]`;

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;
const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover  `}// background-image: url("image/continente.svg");
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-primary-950 `;

const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`pt-6 pb-32 px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`flex flex-col items-center lg:block lg:px-8`;
const RightColumn = tw.div`w-full sm:w-5/6  mt-16 lg:mt-0 lg:pl-8`;

const Heading = styled.h1`
  ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-none  `}
  span {
    ${tw`inline-block mt-2 `}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative  px-4 -mx-4 py-2`}
  &::before {
    content: '';
    ${tw`absolute inset-0 `}
  }
`;

const PrimaryAction = tw.button`px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-yellow-950 text-white font-bold rounded shadow transition duration-300 hocus:bg-yellow-950 hocus:text-white focus:shadow-outline `;

export default () => {
  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="/about">About Us</NavLink>
      {/* <NavLink href="#">Contact</NavLink> */}
      {/* <NavLink target="_blank" href="https://hr.afrikancoders.com/login">
        Login
      </NavLink> */}
      {/* <NavLink href="#">Locations</NavLink>
      <NavLink href="#">Pricing</NavLink> */}
    </NavLinks>,
    // <NavLinks key={2}>
    //   <PrimaryLink href="/#">Hire Us</PrimaryLink>
    // </NavLinks>,
  ];

  return (
    <Container>
      <OpacityOverlay />
      <HeroContainer>
        <StyledHeader links={navLinks} />
        <TwoColumn>
          <LeftColumn>
            <ArrowImage className="" src="/arrow-content.svg" />
            {/* <Notification>
              We have now launched operations in Europe.
            </Notification> */}
            <Heading>
              <span className="subtitle">Hire the best</span>
              <br />
              <SlantedBackground>
                developers<p>in Africa!</p>
              </SlantedBackground>
            </Heading>
            <PrimaryAction onClick={() => window.open('https://hr.afrikancoders.com/register')}>
              Find your dev now!
            </PrimaryAction>
          </LeftColumn>
          <RightColumn>
            {/* <StyledResponsiveVideoEmbed
              url="//player.vimeo.com/video/374265101?title=0&portrait=0&byline=0&autoplay=0&responsive=1"
              background="transparent"
            /> */}
            {/* <Image src="/continente.svg" /> */}
            <Image src="/continente.gif" />
          </RightColumn>
        </TwoColumn>
      </HeroContainer>
    </Container>
  );
};
