import React from 'react';
import tw from 'twin.macro'; //eslint-disable-line
import { css } from 'styled-components/macro'; //eslint-disable-line
import AnimationRevealPage from 'helpers/AnimationRevealPage.js';
import FeatureWithSteps from 'components/features/TwoColWithSteps.js';
import Hero from 'components/hero/BackgroundAsImage.js';
import Features from 'components/features/ThreeColWithSideImage.js';
import MainFeature from 'components/features/TwoColSingleFeatureWithStats2.js';
import MainFeature2 from 'components/features/TwoColWithTwoFeaturesAndButtons.js';
import Portfolio from 'components/cards/PortfolioTwoCardsWithImage.js';
import Blog from 'components/blogs/ThreeColSimpleWithImageAndDashedBorder.js';
import Testimonial from 'components/testimonials/TwoColumnWithImageAndProfilePictureReview.js';
import FAQ from 'components/faqs/SimpleWithSideImage.js';
import ContactUsForm from 'components/forms/TwoColContactUsWithIllustration.js';
// import Footer from "components/footers/MiniCenteredFooter.js";
import customerSupportIllustrationSrc from 'images/customer-support-illustration.svg';
import Footer from 'components/footers/SimpleFiveColumn.js';
import macHeroScreenshotImageSrc from 'images/hero-screenshot-2.png';

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <Hero />

      <FeatureWithSteps
        subheading={<Subheading>STEPS</Subheading>}
        heading={
          <>
            Easy to <HighlightedText>Get Started.</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageSrc={macHeroScreenshotImageSrc}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />

      <MainFeature2 />
      {/* <Portfolio /> */}
      <Features heading={<>They trust us</>} />

      <ContactUsForm />
      <Footer />
    </AnimationRevealPage>
  );
};
