import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from 'components/misc/Headings.js';
import { PrimaryButton as PrimaryButtonBase } from 'components/misc/Buttons.js';
import { ReactComponent as BriefcaseIcon } from 'feather-icons/dist/icons/briefcase.svg';
import { ReactComponent as MoneyIcon } from 'feather-icons/dist/icons/dollar-sign.svg';
import TeamIllustrationSrc from 'images/african_people.svg';

const Container = tw.div`relative bg-yellow-950 -mx-8 md:pb-4 md:pt-4`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-2xl mx-auto md:px-20 py-10 lg:py-0`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const HeaderText = tw.p`text-white font-bold text-xl`;

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full `,
]);

const Image2 = tw.img`absolute top-[-81px] lg:top-[-21px] right-[-1px] h-[470px]`;

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Features = tw.div`mt-8 max-w-sm mx-auto md:mx-0 px-8 md:px-0`;
const Feature = tw.div`mt-8 flex items-start flex-col md:flex-row`;

const FeatureIconContainer = styled.div`
  ${tw`mx-auto inline-block border border-white text-center rounded-full p-2 flex-shrink-0`}
  svg {
    ${tw`w-5 h-5 text-white`}
  }
`;

const FeatureText = tw.div`mt-4 md:mt-0 md:ml-4 text-center md:text-left`;
const FeatureHeading = tw.div`font-bold text-lg text-black`;
const FeatureDescription = tw.div`mt-1 text-sm text-white`;

const FeatureContent = tw.div`grid lg:grid-cols-2 grid-cols-1 gap-4 mt-8 mb-8 px-8 lg:px-0`;
const FeatureItem = tw.div`mb-4 lg:mb-0`;
const FeatureItemTitle = tw.div`font-bold text-lg text-black`;
const FeatureItemDescription = tw.div`mt-1 text-sm text-white`;

const PrimaryButton = tw(PrimaryButtonBase)`mt-8 md:mt-10 text-sm inline-block mx-auto md:mx-0`;

export default ({
  subheading = 'Our Expertise',
  heading = (
    <>
      {/* We have the most <span tw="text-primary-500">professional</span> marketing team. */}
      Why Us
    </>
  ),
  description = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  primaryButtonText = 'See Our Portfolio',
  primaryButtonUrl = 'https://timerse.com',
  features = null,
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  /*
   * Change the features variable as you like, add or delete objects
   * `icon` must be a React SVG component. See how BriefcaseIcon is imported above. For a full list of available icons, see Feather Icons.
   */
  const defaultFeatures = [
    {
      Icon: BriefcaseIcon,
      title: 'Dedicated Profissional Teams',
      description:
        'Get access to hire a complete and integrated team quickly and easily. We deliver remote developers with the right skills to accomplish exactly what you want. Invest in multitasking teams with a hight level of synergy',
    },
    {
      Icon: MoneyIcon,
      title: 'Hight Quality Developers',
      description: 'Find in one place, several hight-level programmers with a fast response capacity in a short time.',
    },
    {
      Icon: MoneyIcon,
      title: 'Personalized Support',
      description:
        'We have a team prepared to answer on time and respond effectively, to present you with the best solution.',
    },
    {
      Icon: MoneyIcon,
      title: 'Affordable Price',
      description: 'Have full access to qualified African professionals available worldwide at affordable prices.',
    },
  ];

  if (!features) features = defaultFeatures;

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={TeamIllustrationSrc} />
          {/* <Image2 src="/african_people.svg" /> */}
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {/* <Subheading>{subheading}</Subheading> */}
            <HeaderText>
              <Heading>{heading}</Heading>
            </HeaderText>
            {/* <Description>{description}</Description> */}
            <FeatureContent>
              {features.map((feature, index) => (
                <FeatureItem key={index}>
                  {/* <FeatureIconContainer>{<feature.Icon />}</FeatureIconContainer> */}

                  <FeatureItemTitle>{feature.title}</FeatureItemTitle>
                  <FeatureItemDescription>{feature.description}</FeatureItemDescription>
                </FeatureItem>
              ))}
            </FeatureContent>

            {/* <PrimaryButton as="a" href={primaryButtonUrl}>
              {primaryButtonText}
            </PrimaryButton> */}
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
